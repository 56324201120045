import { Grid } from "@mui/material";
import LayoutPadraoSite from "components/LayoutPadraoSite";
import React from "react";

const Erro404 = () => {
  return (
    <LayoutPadraoSite>
      <Grid container padding={"20px"} justifyContent={"center"} alignItems={"center"} height={"500px"}>
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
          <h1>404</h1>
          <h1 style={{fontSize: "22px!important"}}>Página não encontrada</h1>
          <p style={{fontSize: "14px!important"}}>Desculpe, a página que você está procurando não existe.</p>
        </div>
      </Grid>
    </LayoutPadraoSite>
  );
};

export default Erro404;
